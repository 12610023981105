import validator from 'validator';

const isValidUrl = (url: string, isHttps: boolean = false): boolean => {
    try {
        return validator.isURL(url, {
            require_tld: false,
            require_protocol: true,
            require_valid_protocol: true,
            protocols: ['https'],
        });
    } catch {
        return false;
    }
};

export { isValidUrl };
