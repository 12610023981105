import * as React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import InstallUrlCard from './InstallUrlCard';
import RedirectUrlCard from './RedirectUrlCard';
import AppAuthenticationSettings from '../../../../model/appManagement/AppAuthenticationSettings';
import AppService from '../../../../services/AppService';
import { useNotification } from '../../../../contexts/NotificationProvider';
import Button from '@mui/material/Button';
import { isValidUrl } from '../../../../helpers/urlValidator';
import App from '../../../../model/appManagement/App';
declare module 'yup' {
    interface StringSchema {
        validUrl(
            isHttps: boolean,
            message?: Yup.Message
        ): StringSchema<string | undefined, Yup.AnyObject, undefined, ''>;
    }
}

Yup.addMethod<Yup.StringSchema<string | undefined, Yup.AnyObject, undefined, ''>>(
    Yup.string,
    'validUrl',
    function validateUrl(isHttps: boolean, msg?: Yup.Message) {
        return this.test('validUrl', (value, ctx) => {
            const { path, createError } = ctx;
            if (!value) {
                return true;
            }
            if (!isValidUrl(value, isHttps)) {
                return createError({ path, message: msg });
            }
            return true;
        });
    }
);

interface OAuthSettingsForm {
    installUrl?: string | undefined;
    redirectUrl: string;
}

export interface OAuthSettingsPageProps {
    app: App | undefined;
    isTestEnvironment: boolean;
    onSettingsUpdated: (settings: AppAuthenticationSettings) => void;
}

const OAuthSettingsPage = ({ app, isTestEnvironment, onSettingsUpdated }: OAuthSettingsPageProps) => {
    const environment = isTestEnvironment ? 'Test' : 'Production';
    let authSettings = app?.appAuthenticationSettings.find((x) => x.environment === environment);

    const { displayNotification } = useNotification();
    const intl = useIntl();

    const oauthSettingsFormSchema = Yup.object<OAuthSettingsForm>().shape({
        installUrl: Yup.string().validUrl(
            true,
            intl.formatMessage({ id: 'AppManagement.AppDetails.InvalidUrlMessage' })
        ),
        redirectUrl: Yup.string()
            .validUrl(true, intl.formatMessage({ id: 'AppManagement.AppDetails.InvalidUrlMessage' }))
            .required(intl.formatMessage({ id: 'AppManagement.AppDetails.RedirectUrlRequiredMessage' })),
    });

    const {
        getValues,
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitted },
    } = useForm<OAuthSettingsForm>({
        defaultValues: {
            installUrl: authSettings?.installUrl,
            redirectUrl: authSettings?.redirectUrl,
        },
        resolver: yupResolver(oauthSettingsFormSchema),
    });

    const getInstallUrl = () => getValues('installUrl') || '';
    const getRedirectUrl = () => getValues('redirectUrl');

    const saveApiSettings = (data: OAuthSettingsForm) => {
        if (authSettings) {
            let updatedSettings: AppAuthenticationSettings = {
                ...authSettings,
                installUrl: data.installUrl,
                redirectUrl: data.redirectUrl,
            };
            AppService.saveApplicationAuthenticationSettings(updatedSettings).then((response) => {
                onSettingsUpdated(response);
                displayNotification({
                    message: intl.formatMessage({ id: 'AppManagement.AppDetails.SettingSavedSuccessMessage' }),
                });
            });
        }
    };

    if (!authSettings) return <></>;

    return (
        <>
            <div>
                <h3 className="mb-3">
                    {environment.toUpperCase()} <FormattedMessage id="AppManagement.AppDetails.SettingsMenuLabel" />
                </h3>
                <p className="mb-2">
                    <FormattedMessage id="AppManagement.AppDetails.SettingsDescription" />
                </p>
            </div>
            <InstallUrlCard register={register('installUrl')} errors={errors.installUrl} getValue={getInstallUrl} />
            <RedirectUrlCard register={register('redirectUrl')} errors={errors.redirectUrl} getValue={getRedirectUrl} />
            <div className="oauth-card">
                <div className="d-flex justify-content-start w-100">
                    <Button
                        disabled={isSubmitted && !isValid}
                        disableElevation
                        onClick={handleSubmit(saveApiSettings)}
                        variant="contained"
                    >
                        {intl.formatMessage({ id: 'Common.Save' })}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default OAuthSettingsPage;
