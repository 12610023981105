import TextField from '@mui/material/TextField';
import * as React from 'react';
import CopyToClipboard from '../../../common/CopyToClipboard';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

const TextFieldWIthCopyToClipboard = ({
    id,
    placeholder,
    errors,
    register,
    readOnly = false,
    value,
    getValue,
}: TextFieldWIthCopyToClipboardProps) => {
    return (
        <TextField
            fullWidth={true}
            InputProps={{
                readOnly: readOnly,
                sx: {
                    paddingRight: 0,
                },
                endAdornment: (
                    <CopyToClipboard
                        id={id}
                        buttonProps={{
                            sx: {
                                ':hover': {
                                    backgroundColor: 'primary.main',
                                },
                                backgroundColor: 'primary.main',
                                borderRadius: '0 4px 4px 0',
                                padding: '16px',
                            },
                        }}
                        isWhite={true}
                        getValue={getValue}
                    />
                ),
            }}
            {...register}
            value={value}
            placeholder={placeholder}
            variant="outlined"
            error={!!errors}
            helperText={errors?.message}
            data-testid={id}
        />
    );
};

export interface TextFieldWIthCopyToClipboardProps {
    id: string;
    getValue: () => string;
    value?: string;
    placeholder?: string;
    readOnly?: boolean;
    errors?: FieldError;
    register?: UseFormRegisterReturn;
}

export default TextFieldWIthCopyToClipboard;
